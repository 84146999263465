html {
  background-color: rgb(42, 45, 82);
  height: 100%;
}

body {
  height: 100%;
  width: 100vw;
  max-height: 100%;
}

nav {
  background-color: rgb(133, 137, 190);
  display: flex;
  justify-content: center;
  width: 100%;
}

h1.title {
  color: rgb(42, 45, 82);
}

h1.title.info {
  padding-right: 1em;
  margin-bottom: auto;
  margin-top: auto
}

h1.title.logo {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.AfidCam {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.ViewFinder {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 100%;
}

.ViewFinder video,
.ViewFinder canvas,
.ViewFinder img {
  object-fit: contain;
  object-position: top;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.ViewFinder .progress-spinner {
  font-size: 20em;
  color: rgb(133, 137, 190);
}

.CameraControl {
  color: rgb(42, 45, 82);
  background-color: rgb(42, 45, 82);
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 5em;
  justify-self: flex-end;
  justify-self: flex-end;

  bottom: 0px;
  width: 100vw;
  padding: 2vh 0;
  box-sizing: border-box;
  flex-grow: 1;
}

.CameraControl .camera-circle {
  color: rgb(133, 137, 190);
}

.CameraControl .camera-icon {
  font-size: 0.7em;
}

#root {
  height: 100%;
  max-height: 100%;
}

@keyframes cameraFlash {
  0% {
    filter: brightness(10);
  }

  30% {
    filter: brightness(10);
  }

  100% {
    filter: brightness(1);
  }
}

.camera-flash {
  animation: cameraFlash;
  animation-duration: 0.2s;
}

.flash-bg {
  background-color: white;
}